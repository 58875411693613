<script setup>
    import { useUserStore } from "@/stores/user"
    import { ElMessageBox } from "element-plus"
    import { useRouter, useRoute } from "vue-router"
    import PERMISSIONS from "@/utils/permissions/index.js"

    defineProps({
        user: {
            type: Object,
            required: true,
        },
    })

    const router = useRouter()
    const route = useRoute()
    const userStore = useUserStore()

    /**
     * 登出
     */
    async function logout() {
        try {
            await ElMessageBox.confirm("確定登出系統?", "確認", {
                type: "warning",
                confirmButtonText: "登出",
                cancelButtonText: "取消",
                center: true,
                customClass:
                    "[&>*:first-child>*:first-child]:!justify-start [&_*]:text-left [&>*:last-child]:!justify-end",
            })
            userStore.logout()
        } catch (error) {
            return
        }
    }
</script>

<template>
    <el-popover
        placement="bottom-start"
        :width="200"
        trigger="click"
        class="overflow-hidden"
        popper-class="!px-0 max-h-[calc(100vh-60px)] overflow-auto !pt-0 !pb-[6px]"
    >
        <template #reference>
            <div class="user-avatar">
                <base-svg-icon
                    name="user"
                    color="var(--el-color-black)"
                ></base-svg-icon>
            </div>
        </template>
        <div class="p-3">
            <span class="font-medium text-base">{{ user?.name }}</span>
            <h4 class="user-email text-color-[var(--el-text-color-secondary)]">
                {{ user?.email }}
            </h4>
            <p class="user-role text-color-[var(--el-text-color-secondary)]">
                {{ user?.roleName }}
            </p>
            <p
                class="user-department text-color-[var(--el-text-color-secondary)] !mb-0"
            >
                {{ user?.department }}
            </p>
        </div>
        <el-divider class="m-0" />
        <el-menu
            :default-active="route.name"
            class="bg-[var(--el-fill-color-lighter)]"
        >
            <div
                class="h-10 w-full flex items-center px-2 bg-[var(--el-color-white)]"
            >
                <base-svg-icon
                    name="bell"
                    color="var(--el-color-black)"
                    class="mr-3"
                ></base-svg-icon>
                <span>消息中心</span>
            </div>
            <el-menu-item disabled class="!px-2 h-10 indent-lg">
                <span>消息通知 (99)</span>
            </el-menu-item>
            <el-menu-item disabled class="!px-2 h-10 indent-lg">
                <span>編審中心</span>
            </el-menu-item>
            <el-menu-item
                :index="'download-list'"
                class="!px-2 h-10 indent-lg"
                @click="router.push({ name: 'download-list' })"
            >
                <span>下載中心</span>
            </el-menu-item>

            <div
                class="h-10 w-full flex items-center px-2 bg-[var(--el-color-white)]"
            >
                <base-svg-icon
                    name="users"
                    color="var(--el-color-black)"
                    class="mr-3"
                ></base-svg-icon>
                <span>權限管理</span>
            </div>
            <base-access-control-config
                :require-permission="PERMISSIONS.EDIT_SYSTEM_SETTING"
                :asset-data="null"
                :config="{
                    requireValidateAssets: false,
                }"
                class="w-full"
            >
                <el-menu-item
                    :index="'permissions-setting'"
                    class="system-button !px-2 h-10 indent-lg"
                    @click="router.push({ name: 'permissions-setting' })"
                >
                    <span>角色管理</span>
                </el-menu-item>
            </base-access-control-config>
            <base-access-control-config
                :require-permission="PERMISSIONS.EDIT_SYSTEM_SETTING"
                :asset-data="null"
                :config="{
                    requireValidateAssets: false,
                }"
                class="w-full"
            >
                <el-menu-item
                    :index="'accounts-list'"
                    class="system-button !px-2 h-10 indent-lg"
                    @click="router.push({ name: 'accounts-list' })"
                >
                    <span>使用者管理</span>
                </el-menu-item>
            </base-access-control-config>

            <div
                class="h-10 w-full flex items-center px-2 bg-[var(--el-color-white)]"
            >
                <base-svg-icon
                    name="setting"
                    color="var(--el-color-black)"
                    class="mr-3"
                ></base-svg-icon>
                <span>平台設定</span>
            </div>
            <el-menu-item disabled class="!px-2 h-10 indent-lg">
                <span>通用管理</span>
            </el-menu-item>
            <el-menu-item disabled class="!px-2 h-10 indent-lg">
                <span>通道管理</span>
            </el-menu-item>
            <el-menu-item disabled class="!px-2 h-10 indent-lg">
                <span>平台管理</span>
            </el-menu-item>
            <base-access-control-config
                :require-permission="PERMISSIONS.EDIT_SYSTEM_SETTING"
                :asset-data="null"
                :config="{
                    requireValidateAssets: false,
                }"
                class="w-full"
            >
                <el-menu-item
                    :index="'system-setting'"
                    class="system-button !px-2 h-10 bg-[var(--el-color-white)]"
                    @click="router.push({ name: 'system-setting' })"
                >
                    <base-svg-icon
                        name="setting"
                        color="var(--el-color-black)"
                        class="mr-3"
                    ></base-svg-icon>
                    <span>系統設定</span>
                </el-menu-item>
            </base-access-control-config>
            <el-menu-item
                :index="'upload'"
                class="!px-2 h-10 bg-[var(--el-color-white)]"
                @click="router.push({ name: 'upload' })"
            >
                <base-svg-icon
                    name="upload"
                    color="var(--el-color-black)"
                    class="mr-3"
                ></base-svg-icon>
                <span>上傳中心</span>
            </el-menu-item>
            <el-menu-item disabled class="!px-2 h-10">
                <base-svg-icon
                    name="help"
                    color="var(--el-color-black)"
                    class="mr-3"
                ></base-svg-icon>
                <span>幫助中心</span>
            </el-menu-item>
            <el-divider class="my-0" />
            <el-menu-item
                class="logout-button !px-2 h-10 bg-[var(--el-color-white)]"
                @click="logout"
            >
                <base-svg-icon
                    name="logout"
                    color="var(--el-color-black)"
                    class="mr-3"
                ></base-svg-icon>
                <span>登出</span>
            </el-menu-item>
        </el-menu>
    </el-popover>
</template>

<style lang="scss" scoped>
    .user-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        clip-path: circle(50% at 50% 50%);
        background-color: $yellow-300;
        cursor: pointer;
    }

    .user-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: $text-primary;
    }

    .user-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
    }

    .user-role {
        margin-bottom: 6px;
    }

    .user-email {
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 4px;
    }

    .user-department {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 12px;
    }

    .system-button {
        &:deep(.text) {
            margin-left: 8px;
        }
    }
    .divider {
        margin: 5px 0px 16px;
    }

    .logout-button {
        font-weight: 500;
        &:hover {
            color: $primary-light-3;
        }
    }

    .el-menu-item:hover {
        background-color: var(--el-fill-color-light);
        color: var(--el-color-black);
    }
</style>
