import { instance } from "../axios"

/**
 * 取得 journeys
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneys(config = {}) {
    return instance.request({
        url: "/journeys/",
        method: "get",
        ...config,
    })
}

/**
 * 取得 journeys 各類別數量
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneysStatusCount(config = {}) {
    return instance.request({
        url: "/journeys/counts/",
        method: "get",
        ...config,
    })
}

/**
 * 取得單個 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/`,
        method: "get",
        ...config,
    })
}

/**
 * 新增 journey
 * @param {object} config config
 * @returns {Promise} API response
 */
export function createJourney(config = {}) {
    return instance.request({
        url: "/journeys/",
        method: "post",
        ...config,
    })
}

/**
 * 編輯 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/`,
        method: "put",
        ...config,
    })
}

/**
 * 部分更新 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function patchJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/`,
        method: "patch",
        ...config,
    })
}

/**
 * 刪除 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function deleteJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 送審 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function submitJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/submit/`,
        method: "post",
        ...config,
    })
}

/**
 * 審核 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function reviewJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/review/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得 journeys 審核人員
 * @param {string} uuid id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneysReviewers(uuid, config = {}) {
    return instance.request({
        url: `/journeys/${uuid}/reviewers/`,
        method: "get",
        ...config,
    })
}

/**
 * 暫停 journey
 * @param {string} id id
 * @param {object} config config
 * @returns {Promise} API response
 */
export function freezeJourney(id, config = {}) {
    return instance.request({
        url: `/journeys/${id}/terminate/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得旅程成效
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneyEffective(uuid, config = {}) {
    return instance.request({
        url: `/journeys/${uuid}/analytics/performance/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得旅程成效名單
 * @param {string} uuid uuid
 * @param {string} nodeShortId nodeShortId
 * @param {string} port port
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneyEffectiveUserList(
    uuid,
    nodeShortId,
    port,
    config = {}
) {
    return instance.request({
        url: `/journeys/${uuid}/nodes/${nodeShortId}/logs/${port}/`,
        method: "get",
        ...config,
    })
}

/**
 * 匯出旅程成效名單
 * @param {string} uuid uuid
 * @param {string} nodeShortId nodeShortId
 * @param {string} port port
 * @param {object} config config
 * @returns {Promise} API response
 */
export function exportJourneyEffectiveUserList(
    uuid,
    nodeShortId,
    port,
    config = {}
) {
    return instance.request({
        url: `/journeys/${uuid}/nodes/${nodeShortId}/logs/${port}/export/`,
        method: "post",
        ...config,
    })
}

/**
 * 批次更新旅程排序
 * @param {object} config config
 * @returns {Promise} API response
 */
export function updateJourneyPriority(config = {}) {
    return instance.request({
        url: `/journeys/priority/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得旅程劇本分析大部分資訊
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneyAnalytics(uuid, config = {}) {
    return instance.request({
        url: `/journeys/${uuid}/analytics/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得旅程劇本群組參與分析
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneyAnalyticsCohort(uuid, config = {}) {
    return instance.request({
        url: `/journeys/${uuid}/analytics/cohort/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得劇本有效母數節點列表
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneySourceNode(uuid, config = {}) {
    return instance.request({
        url: `/journeys/${uuid}/source-node/`,
        method: "get",
        ...config,
    })
}
/**
 * 取得母數節點商品分類
 * @param {object} config config
 * @returns {Promise} API response
 */
export function getJourneyItemList(config = {}) {
    return instance.request({
        url: `/journeys/item-list/`,
        method: "get",
        ...config,
    })
}

/**
 * 匯出旅程分析結果
 * @param {string} uuid uuid
 * @param {object} config config
 * @returns {Promise} API response
 */
export function exportJourneyAnalytics(uuid, config = {}) {
    return instance.request({
        url: `/journeys/${uuid}/analytics/export/`,
        method: "post",
        ...config,
    })
}
