import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

dayjs.extend(isBetween)

/**
 * 檢查目標日期是否介於開始日期與結束日期
 * @param {string | Date} date - 目標日期
 * @param {string | Date} startAt - 起始日期
 * @param {string | Date} endAt - 結束日期
 * @param {boolean} isInclude - 是否包含起始日、結束日
 * @returns {boolean} - 是否在此區間
 */
export function checkDateIsBetween(date, startAt, endAt, isInclude = true) {
    const start = isInclude ? dayjs(startAt).subtract(1, "Day") : startAt
    const end = isInclude ? dayjs(endAt).add(1, "Day") : endAt
    return dayjs(date).isBetween(
        dayjs(start).format("YYYY/MM/DD"),
        dayjs(end).format("YYYY/MM/DD")
    )
}
