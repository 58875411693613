import DefaultLayout from "@/layouts/DefaultLayout/DefaultLayout.vue"
import PERMISSIONS from "@/utils/permissions/index.js"
import createAuthRouteMeta from "@/utils/createAuthRouteMeta"
import useRouterProtect from "@/composables/useRouterProtect/index.js"
import * as journeysApi from "@/api/journeys/api"
import dayjs from "dayjs"
import useInitDateRange from "@/composables/useInitDateRange"

export default [
    {
        path: "/automated-marketing",
        component: DefaultLayout,
        redirect: "/automated-marketing/journeys",
        name: "automated-marketing",
        meta: {
            isShow: true,
            name: "智能營運",
            index: "3",
            position: "left",
            menuGroup: "automated-marketing",
        },
        children: [
            {
                path: "journeys", // 旅程劇本列表
                component: () =>
                    import(
                        "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysPage.vue"
                    ),
                name: "journeys-list",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.GET_JOURNEY],
                    isSubShow: true,
                    subIndex: "1",
                    subGroup: "journey",
                    subGroupName: "旅程劇本",
                },
            },
            {
                path: "journeys/:id", // 旅程劇本細節頁
                component: () =>
                    import(
                        "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysDetailPage.vue"
                    ),
                name: "journeys-detail",
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.GET_JOURNEY],
                    isSubShow: false,
                    subGroup: "journey",
                }),
                props: true,
                children: [
                    {
                        path: "", // 旅程劇本細節 - 內容
                        component: () =>
                            import(
                                "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysDetailContentPage.vue"
                            ),
                        name: "journeys-detail-content",

                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.GET_JOURNEY,
                                api: journeysApi.getJourney,
                            })

                            if (path === true) {
                                to.params.initialJourneyResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "setting", // 旅程劇本細節 - 設定
                        component: () =>
                            import(
                                "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysDetailSettingPage.vue"
                            ),
                        name: "journeys-detail-setting",

                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.GET_JOURNEY,
                                api: journeysApi.getJourney,
                            })

                            if (path === true) {
                                to.params.initialJourneyResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "effective", // 旅程劇本 - 成效
                        component: () =>
                            import(
                                "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysDetailEffectivePage.vue"
                            ),
                        name: "journeys-detail-effective",

                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS:
                                    PERMISSIONS.GET_JOURNEY_PERFORMANCE,
                                api: journeysApi.getJourney,
                            })

                            if (path === true) {
                                to.params.initialJourneyResponse = response
                                const startAt = dayjs(response.data.startAt)
                                const endAt = dayjs(response.data.endAt)
                                const [start, end] = useInitDateRange(
                                    startAt,
                                    endAt,
                                    dayjs(),
                                    1
                                )
                                to.params.searchDates = [start, end]
                            }
                            return path
                        },
                    },
                    {
                        path: "analysis", // 旅程劇本 - 分析
                        component: () =>
                            import(
                                "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysDetailAnalysisPage.vue"
                            ),
                        name: "journeys-detail-analysis",
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS:
                                    PERMISSIONS.GET_JOURNEY_PERFORMANCE,
                                api: journeysApi.getJourney,
                            })

                            const sourceNodes =
                                await journeysApi.getJourneySourceNode(
                                    to.params.id
                                )
                            if (path === true) {
                                to.params.initialJourneyResponse = response
                                to.params.searchDates = [
                                    dayjs()
                                        .subtract(30, "day")
                                        .hour(0)
                                        .minute(0)
                                        .second(0)
                                        .millisecond(0)
                                        .toISOString(),
                                    dayjs()
                                        .subtract(1, "day")
                                        .hour(23)
                                        .minute(59)
                                        .second(59)
                                        .millisecond(999)
                                        .toISOString(),
                                ]
                                to.params.sourceNodes =
                                    sourceNodes?.data.results || []
                            }
                            return path
                        },
                    },
                ],
            },
            {
                path: "journeys/add", // 新增旅程劇本
                component: () =>
                    import(
                        "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysAddPage.vue"
                    ),
                name: "add-journeys",
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.ADD_JOURNEY],
                    isSubShow: false,
                    subGroup: "journey",
                }),
            },
            {
                path: "journeys/:id/edit", // 編輯旅程劇本
                component: () =>
                    import(
                        "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysEditPage.vue"
                    ),
                name: "edit-journeys",
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.EDIT_JOURNEY],
                    isSubShow: false,
                    subGroup: "journey",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.EDIT_JOURNEY,
                        api: journeysApi.getJourney,
                    })

                    if (path === true) {
                        to.params.initialJourneyResponse = response
                    }
                    return path
                },
                children: [
                    {
                        path: "content", // 旅程劇本編輯 - 劇本內容
                        component: () =>
                            import(
                                "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysEditContentPage.vue"
                            ),
                        name: "edit-journeys-content",
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.EDIT_JOURNEY,
                                api: journeysApi.getJourney,
                            })

                            if (path === true) {
                                to.params.initialJourneyResponse = response
                            }
                            return path
                        },
                    },
                    {
                        path: "setting", // 旅程劇本編輯 - 劇本設定
                        component: () =>
                            import(
                                "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysEditSettingPage.vue"
                            ),
                        name: "edit-journeys-setting",
                        props: true,
                        beforeEnter: async (to) => {
                            const [path, response] = await useRouterProtect({
                                to,
                                PERMISSIONS: PERMISSIONS.EDIT_JOURNEY,
                                api: journeysApi.getJourney,
                            })

                            if (path === true) {
                                to.params.initialJourneyResponse = response
                            }
                            return path
                        },
                    },
                ],
            },
            {
                path: "journeys/:id/review", // 審核旅程劇本
                component: () =>
                    import(
                        "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysReviewPage.vue"
                    ),
                name: "review-journeys",
                meta: createAuthRouteMeta({
                    permissions: [PERMISSIONS.REVIEW_JOURNEY],
                    isSubShow: false,
                    subGroup: "journey",
                }),
                props: true,
                beforeEnter: async (to) => {
                    const [path, response] = await useRouterProtect({
                        to,
                        PERMISSIONS: PERMISSIONS.REVIEW_JOURNEY,
                        api: journeysApi.getJourney,
                    })

                    if (path === true) {
                        to.params.initialJourneyResponse = response
                    }
                    return path
                },
            },
            {
                path: "journeys/order-set", // 旅程劇本排序設定
                component: () =>
                    import(
                        "@/pages/AutomatedMarketingGroup/AutomatedMarketingJourneysOrderSetPage.vue"
                    ),
                name: "journeys-order-set",
                meta: {
                    requiresAuth: true,
                    permissions: [PERMISSIONS.EDIT_JOURNEY_PRIORITY],
                    isSubShow: true,
                    subIndex: "2",
                    subGroup: "journey-order",
                    subGroupName: "旅程劇本執行排序",
                },
            },
        ],
    },
]
