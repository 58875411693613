import dayjs from "dayjs"
import { checkDateIsBetween } from "@/utils/checkDateIsBetween"

const defaultToday = dayjs()

/**
 * 預設資料時間區間
 * @param {Date} start 生效時間
 * @param {Date} end 結束時間
 * @param {Date} today 今天
 * @param {number} base 基準日 預設為 D - 2
 * @returns {Array} [預設開始時間, 預設結束時間]
 */
export default function (start, end, today = defaultToday, base = 2) {
    today = initialDate(today)
    const baseDay = today.subtract(base, "day")
    const endDate = dayjs(end)
    const startDate = dayjs(start)
    const thirtyDaysBeforeBaseDay = baseDay.subtract(30, "day")
    const thirtyDaysBeforeEndDay = endDate.subtract(30, "day")

    const isEndDateBeforeToday = endDate.isBefore(today)
    let result = []
    if (isEndDateBeforeToday) {
        const isStartDateAfterThirtyDaysBeforeEndDay = startDate.isAfter(
            thirtyDaysBeforeEndDay
        )
        const finalEndDate = dayjs(endDate).isAfter(baseDay) ? baseDay : endDate
        if (isStartDateAfterThirtyDaysBeforeEndDay) {
            result = [startDate, finalEndDate]
        } else {
            result = [thirtyDaysBeforeEndDay, finalEndDate]
        }
    } else {
        const isStartDateAfterThirtyDaysBeforeBaseDay = startDate.isAfter(
            thirtyDaysBeforeBaseDay
        )
        const isStartDateSameTodayOrYesterday = checkDateIsBetween(
            startDate,
            dayjs(baseDay).add(1, "day"),
            today
        )
        // startDate.isSame(today, "day") ||
        // startDate.isSame(today.subtract(1, "day"), "day")
        if (isStartDateAfterThirtyDaysBeforeBaseDay) {
            result = [
                startDate,
                isStartDateSameTodayOrYesterday ? finalDate(today) : baseDay,
            ]
        } else {
            result = [thirtyDaysBeforeBaseDay, baseDay]
        }
    }

    result[1] = finalDate(result[1])
    return result.map((i) => i.toISOString())
}

/**
 * 取得當天起點
 * @param {Date} day 當天日期
 * @returns {Date} 當天起點
 */
export function initialDate(day) {
    return dayjs(day)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0)
}

/**
 * 取得當天終點
 * @param {Date} day 當天日期
 * @returns {Date} 當天終點
 */
export function finalDate(day) {
    return dayjs(day)
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        .set("millisecond", 999)
}
