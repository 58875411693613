import { PERMISSION_LIMIT } from "@/utils/permissions/index.js"

/**
 * 驗證用戶是否具備當前物件的操控權限，權限需要包含以下（全面驗證權限，包含狀態、部門、額外限制）
 * 具備權限
 * 符合編輯限制（例如：部門、本人）
 * 符合物件狀態限制（例如：草稿）
 * @param {string|Array} requiredPermission 當前要符合的權限
 * @param {Reactive} userStore userStore
 * @param {object|null} assetData 資產物件
 * @param {string} assetData.department 創建者部門
 * @param {string} assetData.status 物件狀態
 * @param {string} assetData.creatorUuid 創建者 uuid
 * @param {number} assetData.roleLevel 物件 level（角色）
 * @param {object} config config
 * @param {boolean} config.requiredAll [true] 需要全部符合或單一權限符合才會返回 true
 * @param {boolean} config.requireValidateAssets [true] 是否需要驗證相關的 assets
 * @returns {boolean} 是否具備權限
 */
export default function checkPermissionConfig(
    requiredPermission,
    userStore,
    assetData,
    { requiredAll = true, requireValidateAssets = true } = {}
) {
    const requiredPermissions = Array.isArray(requiredPermission)
        ? requiredPermission
        : [requiredPermission]

    const checkResults = []

    for (let i = 0; i < requiredPermissions.length; i++) {
        const hasPermission = checkOnePermissions(
            requiredPermissions[i],
            userStore.formatUserPermissions
        )

        if (!hasPermission) {
            checkResults.push(false)
            continue
        }

        if (!requireValidateAssets) {
            checkResults.push(true)
            continue
        }

        const effectiveRangeRestriction = getEffectiveRangeRestriction(
            requiredPermissions[i],
            userStore.userPermissions
        )

        // 當具備檢查條件，進行檢查
        if (effectiveRangeRestriction) {
            // 該權限是否限制本人創建
            if (getIsLimitSamePeople(effectiveRangeRestriction)) {
                if (assetData.creatorUuid !== userStore.userInfo.uuid) {
                    checkResults.push(false)
                    continue
                }
            }

            // 該權限是否限制同部門
            if (getIsLimitSameDepartment(effectiveRangeRestriction)) {
                if (
                    assetData.department !== userStore.userInfo.departmentUuid
                ) {
                    checkResults.push(false)
                    continue
                }
            }
            // 該權限是否限制同部門角色階級更低
            if (
                effectiveRangeRestriction.some(
                    (item) => item === PERMISSION_LIMIT.LOWER_LEVEL_DEPARTMENT
                )
            ) {
                if (assetData.roleLevel >= userStore.userInfo.level) {
                    checkResults.push(false)
                    continue
                }
            }

            // 該權限是否限制同部門角色階級相同或更低
            if (
                effectiveRangeRestriction.some(
                    (item) => item === PERMISSION_LIMIT.SAME_OR_LOWER_LEVEL
                )
            ) {
                if (assetData.roleLevel > userStore.userInfo.level) {
                    checkResults.push(false)
                    continue
                }
            }
        }
        const statusBlacklistRestriction = getStatusBlacklistRestriction(
            requiredPermissions[i],
            userStore.userPermissions
        )

        const statusWhitelistRestriction = getStatusWhitelistRestriction(
            requiredPermissions[i],
            userStore.userPermissions
        )
        // 該權限是否限制物件操作狀態
        if (statusWhitelistRestriction.length) {
            if (
                !statusWhitelistRestriction.some(
                    (item) => item === assetData.status
                )
            ) {
                checkResults.push(false)
                continue
            }
        } else if (statusBlacklistRestriction.length) {
            if (
                statusBlacklistRestriction.some(
                    (item) => item === assetData.status
                )
            ) {
                checkResults.push(false)
                continue
            }
        }

        checkResults.push(true)
    }

    if (requiredAll) return checkResults.every((item) => item === true)
    else return checkResults.some((item) => item === true)
}

/**
 * 確認用戶具備此權限
 * @param {string} permission 要驗證是否擁有的權限
 * @param {Array} userPermissions 用戶當前擁有的權限
 * @returns {boolean} 是否具備此權限
 */
export function checkOnePermissions(permission, userPermissions) {
    if (
        userPermissions &&
        userPermissions.some((item) => item === permission)
    ) {
        return true
    }
    return false
}

/**
 * 取得該權限角色對應的限制條件
 * @param {string} requiredPermission 要驗證的條件
 * @param {object} userStorePermissions userStorePermissions
 * @returns {string[]| undefined} 權限限制
 */
export function getEffectiveRangeRestriction(
    requiredPermission,
    userStorePermissions
) {
    const permissions = userStorePermissions.results.filter(
        (item) => item.permissionId === requiredPermission
    )

    // 如果找不到匹配的權限，返回空數組
    if (permissions.length === 0) return []

    // 合併所有有效範圍條件
    const mergedEffectiveRange = permissions.reduce((acc, permission) => {
        if (permission.effectiveRange) {
            acc.push(...permission.effectiveRange)
        }
        return acc
    }, [])

    // 移除重複條件並返回
    return [...new Set(mergedEffectiveRange)]
}

/**
 * 該權限條件是否限制同個創建人
 * @param {string[]} effectiveRangeRestriction 權限限制
 * @returns {boolean} 是否限制
 */
function getIsLimitSamePeople(effectiveRangeRestriction) {
    return effectiveRangeRestriction.some(
        (item) => item === PERMISSION_LIMIT.SAME_PEOPLE
    )
}

/**
 * 該權限條件是否限制同部門
 * @param {string[]} effectiveRangeRestriction 權限限制
 * @returns {boolean} 是否限制
 */
export function getIsLimitSameDepartment(effectiveRangeRestriction) {
    return effectiveRangeRestriction.some(
        (item) => item === PERMISSION_LIMIT.SAME_DEPARTMENT
    )
}

/**
 * 該權限條件是否限制物件操作狀態
 * @param {string} requiredPermission 要驗證的條件
 * @param {object} userStorePermissions userStorePermissions
 * @returns {Array} 是否限制
 */
function getStatusBlacklistRestriction(
    requiredPermission,
    userStorePermissions
) {
    const permissions = userStorePermissions.results.filter(
        (item) => item.permissionId === requiredPermission
    )

    // 如果找不到匹配的權限，返回空數組
    if (permissions.length === 0) return []

    // 合併所有狀態黑名單條件
    const mergedStatusBlacklist = permissions.reduce((acc, permission) => {
        if (permission.statusBlacklist) {
            acc.push(...permission.statusBlacklist)
        }
        return acc
    }, [])

    // 移除重複條件並返回
    return [...new Set(mergedStatusBlacklist)]
}

/**
 * 該權限條件是否限制物件操作狀態
 * @param {string} requiredPermission 要驗證的條件
 * @param {object} userStorePermissions userStorePermissions
 * @returns {Array} 是否限制
 */
function getStatusWhitelistRestriction(
    requiredPermission,
    userStorePermissions
) {
    const permissions = userStorePermissions.results.filter(
        (item) => item.permissionId === requiredPermission
    )

    // 如果找不到匹配的權限，返回空數組
    if (permissions.length === 0) return []

    // 合併所有狀態白名單條件
    const mergedStatusWhitelist = permissions.reduce((acc, permission) => {
        if (permission.statusWhitelist) {
            acc.push(...permission.statusWhitelist)
        }
        return acc
    }, [])

    // 移除重複條件並返回
    return [...new Set(mergedStatusWhitelist)]
}
