import { instance } from "../axios"

/**
 * 取得可用的標籤列表
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagList(config = {}) {
    return instance.request({
        url: "/tags/",
        method: "get",
        ...config,
    })
}

/**
 * 新增一個標籤
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function createdTag(config = {}) {
    return instance.request({
        url: "/tags/",
        method: "post",
        ...config,
    })
}

/**
 * 取得篩選貼標列表
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagAssignerList(config = {}) {
    return instance.request({
        url: "/tags/assigners/",
        method: "get",
        ...config,
    })
}

/**
 * 新增篩選貼標資料
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function addTagAssigner(config = {}) {
    return instance.request({
        url: "/tags/assigners/",
        method: "post",
        ...config,
    })
}

/**
 * 取得單一篩選貼標資料
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagAssigner(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/`,
        method: "get",
        ...config,
    })
}

/**
 * 更新單一篩選貼標資料
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function updateTagAssigner(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/`,
        method: "put",
        ...config,
    })
}

/**
 * 局部更新單一篩選貼標資料
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function patchTagAssigner(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/`,
        method: "patch",
        ...config,
    })
}

/**
 * 停用單一篩選貼標資料
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function disableTagAssigner(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/disable/`,
        method: "post",
        ...config,
    })
}

/**
 * 刪除單一篩選貼標資料
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function deleteTagAssigner(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 新增篩選貼標
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function postTagAssignerAssign(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/assign/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得篩選貼標名單
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagAssignerMembers(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/members/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得篩選貼標分析資料
 * @param {string} taggingUuid tagging uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagAssignerSummary(taggingUuid, config = {}) {
    return instance.request({
        url: `/tags/assigners/${taggingUuid}/summary/`,
        method: "get",
        ...config,
    })
}

/**
 * 比較多筆標籤
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function compareTags(config = {}) {
    return instance.request({
        url: "/tags/compare/",
        method: "post",
        ...config,
    })
}

/**
 * 取得標籤比較結果
 * @param {string} tagComparisonUuid tag comparison uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagsComparison(tagComparisonUuid, config = {}) {
    return instance.request({
        url: `/tags/comparison/${tagComparisonUuid}/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得標籤資訊
 * @param {string} tagUuid tagUuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagInfo(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/`,
        method: "get",
        ...config,
    })
}

/**
 * 編輯標籤名稱
 * @param {string} tagUuid tagUuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function editTag(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/`,
        method: "put",
        ...config,
    })
}

/**
 * 刪除標籤
 * @param {string} tagUuid tagUuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function deleteTag(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/`,
        method: "delete",
        ...config,
    })
}

/**
 * 停用標籤
 * @param {string} tagUuid tagUuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function disableTag(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/disable/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得標籤會員列表
 * @param {string} tagUuid tagUuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagMemberList(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/members/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得標籤分析資料
 * @param {string} tagUuid tagUuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function getTagSummary(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/summary/`,
        method: "get",
        ...config,
    })
}

/**
 * 匯出標籤會員名單
 * @param {string} tagUuid tag uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function exportTagNameList(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/export/`,
        method: "post",
        ...config,
    })
}

/**
 * 取得標籤圖表資料
 * @param {object} tagUuid tag uuid
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function tagsFetch(tagUuid, config = {}) {
    return instance.request({
        url: `/tags/${tagUuid}/fetch/`,
        method: "get",
        ...config,
    })
}

/**
 * 取得貼標回中台
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function tagsSyncList(config = {}) {
    return instance.request({
        url: `/tags/sync-list/`,
        method: "get",
        ...config,
    })
}

/**
 * 更新貼標回中台標籤
 * @param {object} config axios config
 * @returns {Promise} API response
 */
export function updateTagsSyncList(config = {}) {
    return instance.request({
        url: `/tags/sync-list/`,
        method: "post",
        ...config,
    })
}
